import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { headingStyle } from './inlineStyles';
import { Gallery } from './Gallery';
import './OurProducts.css';

export const OurProducts: React.FC = () => (
  <div id="ourProducts">
    <Container
      style={{ padding: '0', overflowX: 'hidden', fontSize: '1.1rem' }}
      fluid
    >
      <div className="desktop-view">
        <Row>
          <Col md="5">
            <div className="product-intro">
              <h2 style={headingStyle}>Our Products</h2>
              <hr
                style={{
                  borderWidth: '4px',
                  width: '25ch',
                  backgroundColor: '#FFE200',
                  marginLeft: 0,
                }}
              />
              <p>
                VJ-03 is our premier ventilator model. An elegant solution for
                an array of patient conditions and clinical settings.
              </p>
              <p>
                Allowing for both invasive and non-invasive ventilation, our
                turbine powered ventilator monitors a range of advanced patient
                parameters and provides for a level of functionality that you
                would only expect to find in a high performing ventilator.
              </p>
              <p>
                In short, the high performance and reliability of the VJ 03
                coupled with its affordable pricing makes it exceptional value
                for money.
              </p>
            </div>
          </Col>
          <Col md="7">
            <Gallery />
            <div className="key-features">
              <div className="key-features-content">
                <h2 style={headingStyle}>Key Highlights</h2>
                <hr
                  style={{
                    borderWidth: '4px',
                    width: '25ch',
                    backgroundColor: '#FFF',
                    marginLeft: 0,
                  }}
                />
                <ul>
                  <li>Adult and paediatric ventilation</li>
                  <li> Built in High Performance Turbine</li>
                  <li> Microprocessor Controlled</li>
                  <li> 10.1” touch screen</li>
                  <li> 2 hour battery backup</li>
                  <li> 21-100% FiO2</li>
                  <li> Real time Pressure, Flow {'&'} Volume Graphs</li>
                  <li> Monitoring of all critical parameters</li>
                  <li> Event Log</li>
                  <li> 48 hour History</li>
                  <li>Trolley</li>
                  <li>Humidifier (Optional)</li>
                </ul>
              </div>
              <img src="/productsShape.svg" className="key-features-image" />
            </div>
          </Col>
        </Row>
      </div>
      <div className="mobile-view">
        <div className="mob-product-intro">
          <h2 style={headingStyle}>Our Products</h2>
          <hr
            style={{
              borderWidth: '4px',
              width: '25ch',
              backgroundColor: '#FFE200',
              marginLeft: 0,
            }}
          />
          <p>
            VJ-03 is our premier ventilator model. An elegant solution for an
            array of patient conditions and clinical settings.
          </p>
          <p>
            Allowing for both invasive and non-invasive ventilation, our turbine
            powered ventilator monitors a range of advanced patient parameters
            and provides for a level of functionality that you would only expect
            to find in a high performing ventilator.
          </p>
          <p>
            In short, the high performance and reliability of the VJ 03 coupled
            with its affordable pricing makes it exceptional value for money.
          </p>
        </div>
        <div className="mobile-gallery">
          <Gallery />
        </div>
        <div className="mob-key-features">
          <div className="mob-product-heading">
            <h2 style={headingStyle}>Key Highlights</h2>
            <hr
              style={{
                borderWidth: '4px',
                width: '25ch',
                backgroundColor: '#FFF',
                marginLeft: 0,
              }}
            />
          </div>
          <ul>
            <li>Adult and paediatric ventilation</li>
            <li> Built in High Performance Turbine</li>
            <li> Microprocessor Controlled</li>
            <li> 10.1” touch screen</li>
            <li> 2 hour battery backup</li>
            <li> 21-100% FiO2</li>
            <li> Real time Pressure, Flow {'&'} Volume Graphs</li>
            <li> Monitoring of all critical parameters</li>
            <li> Event Log</li>
            <li> 48 hour History</li>
            <li>Trolley</li>
            <li>Humidifier (Optional)</li>
          </ul>
        </div>
      </div>
    </Container>
  </div>
);
