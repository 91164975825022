import React from 'react';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { headingStyle } from './inlineStyles';
import './AboutUs.css';

export const AboutUs: React.FC = () => (
  <Container fluid id="aboutUsSection" style={{ overflowX: 'hidden' }}>
    <Row>
      <Col md="7">
        <h2 style={headingStyle}>Design. Engineering. Technology.</h2>
        <hr
          style={{
            borderWidth: '4px',
            width: '25ch',
            backgroundColor: '#FFE200',
          }}
        />
        <p>
          DVB InvenTek is a group of India’s brightest and most innovative
          engineers and designers. We are a team that thrives on applying our
          engineering skills and expertise to solve problems.
        </p>
        <p>
          That’s why when we asked our team in the middle of a global pandemic
          to develop a world class ICU ventilator that could compete with the
          best in the industry there was no hesitation, only eagerness at the
          prospect of a new exciting challenge.
        </p>
        <p>
          Inspired to contribute to the global effort against Covid-19 our team
          worked around the clock. After countless hours of research,
          development and testing, we are proud to say DVB InvenTek is
          manufacturing a world class ICU ventilator. We did not import
          technologies, designs or talent. We used Indian minds and resources to
          build a high performing ICU ventilator designed to be rapidly
          manufactured, yet does not compromise on safety or functionality. A
          ventilator that is priced to be economically accessible in the midst
          of a global pandemic. Value for money. For everyone.
        </p>
        <p>At DVB InvenTek we solve problems. For humanity.</p>
      </Col>
      <Col
        md="4"
        style={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'right',
          marginLeft: '3%',
          verticalAlign: 'center',
        }}
      >
        <Image
          src="/screenImage.svg"
          alt=""
          style={{ width: '100%' }}
          id="screenImage"
        />
      </Col>
    </Row>
    <Row>
      <Col md="5" className="desktop-only illustration">
        <Image src="/aboutUsImage.svg" alt="" style={{ width: '100%' }} />
      </Col>
      <Col md="7" className="desktop-only" style={{ padding: '2%' }}>
        <h2 style={headingStyle}>About our Founder</h2>
        <hr
          style={{
            borderWidth: '4px',
            width: '25ch',
            backgroundColor: '#FFE200',
          }}
        />
        <p>
          An engineer first, DVB InvenTek’s founding partner, Mr. Darshan
          Bhatia, takes a hands on approach to all company projects.
        </p>
        <p>
          Mr Bhatia is also the driving force behind DVB Design and Engineering,
          India’s leading tool and die manufacturer, designing and building
          Metal Stamping dies for the Global automotive and appliance industry.
          This experience and expertise in building specialized products for the
          global automotive and appliance industry uniquely positions DVB to
          manufacture world class, cost competitive products that meet demanding
          global standards.
        </p>
        <p>
          For more information about DVB Design and Engineering visit us at{' '}
          <a
            href="https://www.dvbdesign.com"
            target="_blank"
            style={{ textDecoration: 'underline' }}
          >
            www.dvbdesign.com
          </a>
        </p>
      </Col>
      <div className="mobile-only" style={{ padding: '2%' }}>
        <p>
          An engineer first, DVB InvenTek’s founding partner, Mr. Darshan
          Bhatia, takes a hands on approach to all company projects.
        </p>
        <p>
          Mr Bhatia is also the driving force behind DVB Design and Engineering,
          India’s leading tool and die manufacturer, designing and building
          Metal Stamping dies for the Global automotive and appliance industry.
          This experience and expertise in building specialized products for the
          global automotive and appliance industry uniquely positions DVB to
          manufacture world class, cost competitive products that meet demanding
          global standards.
        </p>
        <p>
          For more information about DVB Design and Engineering visit us at{' '}
          <a href="https://www.dvbdesign.com" target="_blank">
            www.dvbdesign.com
          </a>
        </p>

        <Image src="/aboutUsImage.svg" alt="" style={{ width: '100%' }} />
      </div>
    </Row>
  </Container>
);
