import { CSSProperties } from 'react';

const headingStyle: CSSProperties = {
  textTransform: 'uppercase',
};

const pStyle: CSSProperties = {
  fontSize: '0.9rem',
};

export { headingStyle, pStyle };
