import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './Navigation.css';

const linkStyle = {};

interface NavProps {}
export const Navigation: React.FC<NavProps> = ({ children }) => {
  return (
    <div className="layout">
      <Navbar collapseOnSelect expand="md" sticky="top" bg="light">
        <Navbar.Brand href="#home">
          <img src="/logo.svg" alt="" id="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav
            className="ml-auto"
            justify
            style={{ fontFamily: `'Exo', sans-serif` }}
          >
            <Nav.Link href="#aboutUsSection" style={linkStyle}>
              About us
            </Nav.Link>
            <Nav.Link href="#ourProducts">Products</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            <Nav.Link href="/brochure.pdf" target="_blank">
              Brochure
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="navChild">{children}</div>
    </div>
  );
};
