import React from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { headingStyle } from './inlineStyles';
import './Details.css';

export const Details: React.FC<{}> = ({}) => (
  <Container
    style={{ padding: 0, marginTop: '0px', overflowX: 'hidden' }}
    fluid
  >
    <Row>
      <Col md="6">
        <Row
          className="short-row"
          style={{
            backgroundColor: '#1F2D5F',
            paddingLeft: '5%',
          }}
        >
          <div className="ventilation-modes" style={{ color: 'white' }}>
            <h4 style={headingStyle}>Ventilation Modes</h4>
            <ul>
              <li>Invasive modes</li>
              <ul>
                <li>PC-CMV</li>
                <li>PC-SIMV</li>
                <li>VC-SIMV</li>
                <li>VC-CMV</li>
                <li>PSV</li>
                <li>PRVC</li>
                <li>ACV</li>
              </ul>
              <li>Non-invasive modes</li>
              <ul>
                <li>CPAP</li>
                <li>BPAP</li>
              </ul>
            </ul>
          </div>
        </Row>
        <Row className="long-row" style={{ backgroundColor: '#F4F7FD' }}>
          <div className="monitored-parameters">
            <h4 style={headingStyle}>Alarms</h4>
            <ul>
              <li> Low / High Tidal Volume</li>
              <li> Low / High Pressure</li>
              <li> High PEEP</li>
              <li> Low / High Rate</li>
              <li> Apnea Time</li>
              <li> Patient Disconnected</li>
              <li> AC Power Disconnected </li>
              <li> Oxygen Cylinder Disconnected</li>
              <li> Battery</li>
              <li> System Issues</li>
              <li> Event Log </li>
              <li> 48 hour history </li>
            </ul>
          </div>
        </Row>
      </Col>
      <Col md="6">
        <Row
          className="long-row"
          style={{
            backgroundColor: '#FFFFFF',
            paddingLeft: '5%',
            color: '#001B66',
          }}
        >
          <div className="ventilation-modes">
            <h4 style={headingStyle}>Ventilator Settings</h4>
            <ul>
              <li>
                Peak Pressure: 60 cm H<sub>2</sub>O{' '}
              </li>
              <li>Peak Respiratory rate: 60 bpm </li>
              <li>Inspiratory Time: 0.3 - 5 seconds</li>
              <li>Tidal volume: 50 ml to 1500 ml </li>
              <li> Peak Flow rate: 240 lpm </li>
              <li>
                PEEP: 0-30 cm H<sub>2</sub>O{' '}
              </li>
              <li>
                {' '}
                Pressure Support: 0 - 40 cm H<sub>2</sub>O{' '}
              </li>
              <li>Inspiratory & Expiratory Hold </li>
              <li>I: E ratio 1: 4 to 4: 1</li>
              <li>Trigger Flow Sensitivity: 1 - 20 lpm </li>
              <li>Leak Volume Compensation</li>
            </ul>
          </div>
        </Row>
        <Row
          className="short-row"
          style={{
            backgroundColor: '#FFDD00',
            overflowY: 'hidden',
          }}
        >
          <div className="monitored-parameters">
            <h4 style={headingStyle}>Monitored Parameters</h4>
            <ul>
              <li>Peak, Insp, Mean {'&'} Plateau Pressure</li>
              <li> PEEP, AutoPEEP</li>
              <li> Minute Volume</li>
              <li> Tidal Volume</li> <li>Spontaneous Minute Volume </li>
              <li>Spontaneous Respiratory Rate </li>
              <li>Rate</li>
              <li> I:E</li>
              <li> Inspiratory {'&'} Expiratory times</li>
              <li> Leak Volume {'&'} Percentage</li>
              <li> Static Compliance</li>
              <li>
                FiO<sub>2</sub>
              </li>
            </ul>
          </div>
        </Row>
      </Col>
    </Row>
  </Container>
);
