import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Gallery.css';

export const Gallery: React.FC = () => (
  <Carousel
    keyboard={false}
    pause="hover"
    style={{ width: '100%' }}
    id="gallery"
  >
    <Carousel.Item>
      <img src="/gallery1.jpg" alt="" style={{ width: '100%' }} />
      <Carousel.Caption>
        <p>Set alarm parameters</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img src="/gallery2.jpg" alt="" style={{ width: '100%' }} />
      <Carousel.Caption>
        <p>Store patient parameters</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img src="/gallery3.jpg" alt="" style={{ width: '100%' }} />
      <Carousel.Caption>
        <p>Multiple invasive/non-invasive modes</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img src="/gallery4.png" alt="" style={{ width: '100%' }} />
      <Carousel.Caption>
        <p>Simple and elegant design</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img src="/gallery7.png" alt="" style={{ width: '100%' }} />
      <Carousel.Caption>
        <p>Removable Expiratory Module</p>
      </Carousel.Caption>
    </Carousel.Item>
    <Carousel.Item>
      <img src="/gallery5.png" alt="" style={{ width: '100%' }} />
      <Carousel.Caption>
        <p>Alarms</p>
      </Carousel.Caption>
      <Carousel.Item>
        <img src="/gallery6.jpg" alt="" style={{ width: '100%' }} />
        <Carousel.Caption>
          <p>Alarm history</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel.Item>
  </Carousel>
);
