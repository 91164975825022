import React, { CSSProperties, useState } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Button from 'react-bootstrap/Button';
import {Image, Modal} from 'react-bootstrap';
import './Jumbotron.css';

const rightShape = process.env.PUBLIC_URL + '/jumbotronShape.svg';

const style: CSSProperties = {
  textAlign: 'left',
  fontFamily: `'Exo', sans-serif`,
  position: 'relative',
  padding: 0,
  paddingLeft: '5%',
  overflowX: 'hidden',
  backgroundImage: `url('/jumbotronImage.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center bottom',
  overflow: 'hidden',
};

const sizeStyle: CSSProperties = { fontSize: '3.5em' };

const buttonStyle: CSSProperties = {
  borderRadius: 0,
  transform: 'skew(-20deg)',
};

const shapeStyle: CSSProperties = {
  position: 'relative',
  textTransform: 'uppercase',
};

export const HeaderJumbotron: React.FC = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Jumbotron style={style} id="home" fluid>
    <div
      className="jumboContent"
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        zIndex: 2,
      }}
    >
      <div
        className="text-content-jumbo"
        style={{ width: '80%', marginTop: '2%' }}
      >
        <div className="taglines">
          <h1 style={sizeStyle}>Design.</h1>
          <h1 style={sizeStyle}>Engineering.</h1>
          <h1 style={sizeStyle}>Technology.</h1>
        </div>
        <p style={{ fontSize: '1.2em' }}>It's in our DNA</p>
        <Button variant="light" style={buttonStyle} onClick={handleShow}>
          <div className="skew-fix" style={{ transform: 'skew(20deg)' }}>
            Watch Video
          </div>
        </Button>
        <Modal show={show} onHide={handleClose} size="xl">
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body>
          <iframe width="100%" height="500em" src="https://www.youtube.com/embed/ehCJsWFSsY8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <div className="shape" style={shapeStyle}>
        <Image
          id="shape"
          src={rightShape}
          style={{ float: 'right', zIndex: 2 }}
        />
        <Image src="/jumbotronVentilator.svg" id="ventilator" />
      </div>
    </div>
    <div className="modal" id="modal1" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Modal title</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close" data-target="modal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>Modal body text goes here.</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </Jumbotron>
  )
};
