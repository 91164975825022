import React from 'react';
import './WhyUs.css';
import { headingStyle } from './inlineStyles';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import icon1 from './assets/cardIcon1.svg';
import icon2 from './assets/cardIcon2.svg';
import icon3 from './assets/cardIcon3.svg';

export const WhyUs: React.FC<{}> = () => (
  <div id="whyUsSection">
    <h2 style={headingStyle}>How are our ventilators different?</h2>
    <hr
      style={{
        borderWidth: '4px',
        width: '25ch',
        backgroundColor: '#FFE200',
      }}
    />
    <div className="intro">
      <p>We have designed and built a ventilator from the ground up.</p>
      <p>
        A team of innovative engineers, DVB InvenTek was prepared for the
        challenge of providing innovative and world class engineering solutions
        for the Covid-19 pandemic
      </p>
    </div>
    <CardDeck className="process-cards">
      <Card className="text-white">
        <Card.Img src="/card1.svg" alt="designCard" />
        <Card.ImgOverlay>
          <Card.Title className="card-title">
            <img src={icon1} alt="designIcon" className="card-icon" />
          </Card.Title>

          <div className="text-content">
            <Card.Text>
              Our design was conceived within the pandemic constraints of
              limited time and limited resources. We did not utilize an existing
              design or open source plan and then search for specified parts and
              components. We started at ground zero. We researched. We consulted
              leading medical experts. We sourced and tested parts in the midst
              of the Indian COVID-19 lockdown.
            </Card.Text>
          </div>
        </Card.ImgOverlay>
      </Card>
      <Card className="text-white">
        <Card.Img src="/card2.svg" alt="engineeringCard" />
        <Card.ImgOverlay>
          <Card.Title className="card-title">
            <img src={icon2} alt="engineeringIcon" className="card-icon" />
          </Card.Title>

          <div className="text-content">
            <Card.Text>
              Beyond engineering specifications, during each and every step of
              the research and development of our ventilator we were focused on
              designing a ventilator we could confidently entrust with the
              health and safety of those that matter the most to us. We designed
              a robust, high performance ventilator for our parents, our wives
              and husbands. We designed a ventilator for our children.
              <p></p>
              <p>We did not compromise.</p>
            </Card.Text>
          </div>
        </Card.ImgOverlay>
      </Card>
      <Card className="text-white">
        <Card.Img src="/card3.svg" alt="resultCard" />
        <Card.ImgOverlay>
          <Card.Title className="card-title">
            <img src={icon3} alt="resultIcon" className="card-icon" />
          </Card.Title>
          <div className="text-content">
            <Card.Text>
              The result? A ventilator which is uncompromising in performance
              but is price sensitive and can be rapidly manufactured.
            </Card.Text>
          </div>
        </Card.ImgOverlay>
      </Card>
    </CardDeck>
  </div>
);
