import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { headingStyle } from './inlineStyles';
const axios = require('axios');

export const ContactForm: React.FC = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);

  const [hasSubmitted, setSubmitted] = useState(false);
  const [isIncomplete, setIncomplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const submitHandler = () => {
    setLoading(true);
    setIncomplete(false);
    setEmailInvalid(false);
    setError(false);
    if (email === '' || message === '' || name === '') {
      setLoading(false);
      setIncomplete(true);
      return;
    } else if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,6}$/.test(email)) {
      setLoading(false);
      setEmailInvalid(true);
      return;
    } else {
      axios.post('/email', {name, email, message}).then((response: any) => {
        console.log(response);
        if(response.data.status === 1) {
          setLoading(false);
          setSubmitted(true);
        } else {
          setLoading(false);
          setError(true);
        }
      });
    }
  };
  return (
    <div
      className="contact-form"
      style={{ padding: '2% 5%', textAlign: 'left' }}
    >
      <h3 style={headingStyle}>Contact Us</h3>
      <hr
        style={{
          borderWidth: '4px',
          width: '20ch',
          backgroundColor: '#FFE200',
          marginLeft: 0,
        }}
      />
      <Form>
        <Form.Group>
          <Form.Control
            type="text"
            name="name"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            isInvalid={isIncomplete && name === ''}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            type="text"
            name="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={emailInvalid || (isIncomplete && email === '')}
          />
        </Form.Group>
        <Form.Group>
          <Form.Control
            as="textarea"
            placeholder="Message"
            rows={5}
            onChange={(e) => setMessage(e.target.value)}
            isInvalid={isIncomplete && message === ''}
          />
        </Form.Group>
        {isIncomplete && (
          <p style={{ fontSize: '0.8rem', color: 'red' }}>
            Please fill all fields.
          </p>
        )}
        {emailInvalid && (
          <p style={{ fontSize: '0.8rem', color: 'red' }}>
            Please enter valid email.
          </p>
        )}
        {error && (
          <p style={{ fontSize: '0.8rem', color: 'red' }}>
            Something went wrong! PLease check your connection and try again.
          </p>
        )}
        {hasSubmitted && (
          <p style={{ fontSize: '0.8rem', color: 'green' }}>
            Message sent! We will get back to you at the earliest.
          </p>
        )}
        <div className="text-right">
          <Button
            variant="light"
            style={{
              borderRadius: 0,
              transform: 'skew(-20deg)',
              fontFamily: 'Exo',
            }}
          >
            <div
              className="skew-fix"
              style={{ transform: 'skew(20deg)', width: '10vw' }}
              onClick={() => submitHandler()}
            >
              SEND
              {isLoading && (
                <Spinner
                  style={{ padding: '1%' }}
                  animation="border"
                  as="span"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
            </div>
          </Button>
        </div>
      </Form>
    </div>
  );
};
