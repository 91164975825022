import React from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { ContactForm } from './ContactForm';
import { QouteForm } from './QuoteForm';

export const Forms: React.FC = () => (
  <div id="contact" style={{ overflowX: 'hidden' }}>
    <div
      className="contact-text"
      style={{ color: '#ADADAD', fontSize: '0.8rem', padding: '1%' }}
    >
      Due to continuous improvements, specifications may change without notice
      or obligation.
    </div>
    <ContactForm />
  </div>
);
