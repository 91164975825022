import React from 'react';
import './App.css';
import { Navigation } from './components/Navigation';
import { HeaderJumbotron } from './components/Jumbotron';
import { AboutUs } from './components/AboutUsSection';
import { WhyUs } from './components/WhyUs';
import { OurProducts } from './components/OurProducts';
import { Details } from './components/Details';
import { Forms } from './components/Forms';
import { Footer } from './components/Footer';

function App() {
  window.addEventListener('hashchange', () => {
    window.scrollTo(window.scrollX, window.scrollY - 140);
  });

  return (
    <div className="App">
      <Navigation>
        <HeaderJumbotron />
        <AboutUs />
        <WhyUs />
        <OurProducts />
        <Details />
        <Forms />
        <Footer />
      </Navigation>
    </div>
  );
}

export default App;
