import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Container from 'react-bootstrap/Container';
import phone from './assets/phoneIcon.svg';
import mail from './assets/mailIcon.svg';
import './Footer.css';

export const Footer: React.FC = () => {
  const legalContent = (
    <div className="legal-content">
      <p>
        Trademarks DVB is a registered trademark DVB and/or trademarks in
        pending applications made by DVB Design and Engineering, registered or
        filed in India as a whole. Other product or solution names may be
        trademarks of DVB Design and Engineering or other companies. Use of the
        registered trademark symbol ® with the first mention of any product or
        solution name on this website indicates a trademark owned by DVB Design
        and Engineering. The information contained in this website is for
        information purposes only. The information is provided by DVB Design and
        Engineering and while we endeavour to keep the information up to date
        and correct, we make no representations or warranties of any kind,
        express or implied, about the completeness, accuracy, reliability,
        suitability or availability with respect to the website or the
        information, products, services, or related graphics contained on the
        website for any purpose. Any reliance you place on such information is
        therefore strictly at your own risk.
      </p>
      <p>Limitation of liability</p>
      <p>
        In no event will we be liable for any loss or damage including without
        limitation, indirect or consequential loss or damage, or any loss or
        damage whatsoever arising from loss of data or profits arising out of,
        or in connection with, the use of this website.
      </p>
      <p>Third party links</p>
      <p>
        Through this website you are able to link to other websites which are
        not under the control of DVB Design and Engineering. We have no control
        over the nature, content and availability of those sites. The inclusion
        of any links does not necessarily imply a recommendation or endorse the
        views expressed within them.
      </p>
      <p>
        {' '}
        Every effort is made to keep the website up and running smoothly.
        However, DVB Design and Engineering takes no responsibility for, and
        will not be liable for, the website being temporarily unavailable due to
        technical issues beyond our control.
      </p>
      <p>Availability of products and service. </p>
      <p>
        Our products and services may not be available in certain countries.
        Please contact us for further information on sales.{' '}
      </p>
      <p>
        The entire content of this Website is subject to copyright with all
        rights reserved.
      </p>
      <p>
        © Copyright by DVB DESIGN AND ENGINEERING, 2020. All rights reserved.
      </p>
    </div>
  );

  const popover = (
    <Popover id="desktop-popover" style={{ width: '90vw', maxWidth: '90vw' }}>
      <Popover.Content>{legalContent}</Popover.Content>
    </Popover>
  );

  return (
    <div className="footer">
      <Container
        style={{ paddingLeft: '5px', paddingTop: '3%', paddingRight: '5px' }}
      >
        <Row>
          <Col
            md="2"
            style={{ padding: 0, marginTop: '-5%', marginLeft: '-3%' }}
          >
            <div className="footer-logo">
              <img src="/footerLogo.svg" className="anti-skew" />
            </div>
          </Col>
          <Col md="4" className="desktop-only legal">
            <OverlayTrigger overlay={popover} trigger="hover" placement="top">
              <p className="hover-trigger">Legal Terms/Conditions</p>
            </OverlayTrigger>
            <p>© DVB Inventek LLP, 2020. All rights reserved.</p>
          </Col>
          <Col md="3" className="contact">
            <p className="d-inline">
              <img src={phone} className="footer-icon" />
              +91 076600 07602
            </p>
          </Col>
          <Col md="3" style={{ marginLeft: '-5px' }}>
            <p id="address">
              Plot 67C ANRICH Industrial Estate, Bollaram, Hyderabad - 502 325,
              India.
            </p>
          </Col>
          <Col className="mobile-only hover-trigger" style={{ padding: '5%' }}>
            <a href="/legal.txt" target="_blank" style={{ color: 'white' }}>
              Legal Terms / Conditions
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
